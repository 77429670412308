// @import "icons.variables";

@import "icons.variables";
@import "icons.attributes-mappings";
@import "icons.helpers";
@import "icons.custom-properties";
@import "icons.font-faces";
@import "icons.placeholder";

[data-icon],
[data-icon-before] {
	&::before {
		@extend %icon;
		content: var(--icon-glyph-before);
		margin-inline-end: var(--icon-margin-after, #{$icon-content-space});
		font-size: var(
			--icon-font-size-before,
			var(--icon-font-size, #{to-rem($pxValue: $icon-size)})
		);
	}
}

[data-icon-after] {
	&::after {
		@extend %icon;
		content: var(--icon-glyph-after);
		margin-inline-start: var(--icon-margin-before, #{$icon-content-space});
		font-size: var(
			--icon-font-size-after,
			var(--icon-font-size, #{to-rem($pxValue: $icon-size)})
		);
	}
}

// Providing a mechanism for text replacements
.is-icon-text-replace {
	&[data-icon],
	&[data-icon-before] {
		@include is-icon-text-replace();
	}

	&[data-icon-after] {
		@include is-icon-text-replace("after");
	}
}
