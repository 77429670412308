@charset "utf-8";

@import "button.variables";

.elm-button {
	align-items: center; // Centering the content vertically and horizontally
	border: 1px solid transparent;
	border-radius: 4px;
	display: inline-flex; // Centering the content vertically and horizontally
	font-size: to-rem($pxValue: 16);
	justify-content: center; // Centering the content vertically and horizontally
	min-height: $button---minHeight;
	padding: to-rem($pxValue: 8) to-rem($pxValue: 16);

	&[data-icon],
	&[data-icon-before] {
		padding-left: to-rem($pxValue: 14);
	}

	&[data-icon-after] {
		padding-right: to-rem($pxValue: 14);
	}
	// Square icon only buttons
	&.is-icon-text-replace {
		width: $button---minHeight;
	}

	/*&:focus {
		border-color: #fff;
	}*/
	/*	&:active {
		filter: brightness(75%);
	}*/

	&%variant-brand-primary,
	&%variant-primary {
		color: $button---color;
	}

	&%variant-brand-primary,
	&%variant-primary {
		font-weight: 700;
	}

	&%variant-brand-primary {
		background-color: $button-brand-primary--backgroundColor;

		&:disabled {
			background-color: unquote(
				$button-brand-primary--backgroundColor + "40"
			);
		}

		&:not(:disabled) {
			&:hover {
				// TODO: This could probably get simplified later on e.g. via CSS variables
				background-color: $button-brand-primary-hover-backgroundColor;
			}

			&:active {
				background-color: #cc0013; // TODO: replace by correct color variable
			}
		}
	}

	&%variant-primary {
		background-color: $button-primary--backgroundColor;

		&:disabled {
			background-color: unquote($button-primary--backgroundColor + "40");
		}

		&:not(:disabled) {
			&:hover {
				// TODO: This could probably get simplified later on e.g. via CSS variables
				background-color: $button-primary-hover-backgroundColor;
			}

			&:active {
				background-color: #363c4a;
			}
		}
	}

	&%variant-secondaryOutline {
		// TODO: This might get replaced by another effect
		backdrop-filter: blur(4px);
		background-color: transparent;
		border-color: $db-color-cool-gray-700;
		color: $db-color-cool-gray-700;

		&:disabled {
			color: unquote($db-color-cool-gray-700 + "80");
			border-color: unquote($db-color-cool-gray-700 + "40");
		}

		&:not(:disabled) {
			&:hover {
				// TODO: This could probably get simplified later on e.g. via CSS variables
				background-color: $button-secondaryOutline-hover-backgroundColor;
			}

			&:active {
				background-color: $button-secondaryOutline-active-backgroundColor;
			}
		}
	}

	&%variant-secondarySolid {
		background-color: $button-secondarySolid--backgroundColor;
		color: $db-color-cool-gray-700;

		&:disabled {
			color: unquote($db-color-cool-gray-700 + "80");
			background-color: unquote(
				$button-secondarySolid--backgroundColor + "40"
			);
		}

		&:not(:disabled) {
			&:hover {
				// TODO: This could probably get simplified later on e.g. via CSS variables
				background-color: $button-secondarySolid-hover-backgroundColor;
			}

			&:active {
				background-color: $button-secondarySolid-active-backgroundColor;
			}
		}
	}

	&%variant-tertiaryPlain {
		background-color: transparent; // shame.css
		border: 2px solid transparent;
		color: $db-color-cool-gray-700;

		&:disabled {
			color: unquote($db-color-cool-gray-700 + "80");
		}

		&:not(:disabled) {
			&:hover {
				// TODO: This could probably get simplified later on e.g. via CSS variables
				background-color: $button-secondaryOutline-hover-backgroundColor;
			}

			&:active {
				background-color: $button-secondaryOutline-active-backgroundColor;
			}
		}
	}

	// Sizes
	&%size-Small {
		min-height: to-rem($pxValue: 36);

		&:not(.is-icon-text-replace) {
			font-size: to-rem($pxValue: 14);
		}

		&[data-icon],
		&[data-icon-before] {
			@include iconMeta(20);
		}

		&[data-icon-after] {
			@include iconMeta($size: 20, $position: "after");
		}
		// Square icon only buttons
		&.is-icon-text-replace {
			width: to-rem($pxValue: 36);
		}
	}

	&%size-Large {
		min-height: to-rem($pxValue: 52);

		&:not(.is-icon-text-replace) {
			font-size: to-rem($pxValue: 20);
		}
		// Square icon only buttons
		&.is-icon-text-replace {
			width: to-rem($pxValue: 52);
		}
	}

	&[data-size="small"] {
		@extend %size-Small;
	}

	&[data-size="large"] {
		@extend %size-Large;
	}

	// Providing the variants as HTML attributes as well
	&[data-variant="brand-primary"] {
		@extend %variant-brand-primary;
	}

	&[data-variant="primary"] {
		@extend %variant-primary;
	}

	&[data-variant="secondary-outline"] {
		@extend %variant-secondaryOutline;
	}

	&[data-variant="secondary-solid"] {
		@extend %variant-secondarySolid;
	}

	&[data-variant="tertiary-plain"] {
		@extend %variant-tertiaryPlain;
	}

	&:disabled {
		color: unquote($button---color + "80");
	}
}
