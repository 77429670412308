@import "../../../css/helpers/functions";
@import "table.variables";
@import "table.helpers";

.cmp-table {
	border-collapse: collapse;

	font-size: var(--table---fontSize, inherit);

	text-align: left;

	// the box-shadow should get displayed including the whole table / the caption as well
	&,
	& > caption {
		box-shadow: $box-shadow-01, $box-shadow-02;
	}

	caption {
		font-weight: 700;
		padding: #{to-rem($pxValue: 16)} #{to-rem($pxValue: 12)};
		text-align: left;
	}

	thead {
		border-bottom: to-rem($pxValue: 1) solid $db-color-cool-gray-700;
		border-top: to-rem($pxValue: 1) solid $db-color-cool-gray-700;

		th {
			color: $db-color-cool-gray-500;
		}
	}

	th {
		font-weight: 400;
	}

	th,
	td {
		padding: $table-densityRegular--padding;

		// Default horizontal and vertical alignment should get inherited
		text-align: inherit;
		vertical-align: inherit;

		&:hover {
			resize: horizontal;

			&:not(:has(.cmp-overflow-menu)) {
				overflow: auto;
			}
		}
	}

	tbody {
		border-bottom: to-rem($pxValue: 1) solid $db-color-cool-gray-700;

		th:first-child {
			border-right: 1px solid $db-color-warm-gray-700;
		}
	}

	// Cell alignments
	thead,
	tbody,
	tr,
	th,
	td {
		&%align-left {
			text-align: left;
		}

		&%align-center {
			text-align: center;
		}

		&%align-right {
			text-align: right;
		}

		&[data-align="left"] {
			@extend %align-left;
		}

		&[data-align="center"] {
			@extend %align-center;
		}

		&[data-align="right"] {
			@extend %align-right;
		}

		&%vertical-align-top {
			vertical-align: top;
		}

		&%vertical-align-middle {
			vertical-align: middle;
		}

		&%vertical-align-bottom {
			vertical-align: bottom;
		}

		&[data-vertical-align="top"] {
			@extend %vertical-align-top;
		}

		&[data-vertical-align="middle"] {
			@extend %vertical-align-middle;
		}

		&[data-vertical-align="bottom"] {
			@extend %vertical-align-bottom;
		}
	}

	// Border
	&[data-border="horizontal"],
	&[data-border="around"] {
		tbody {
			tr:not(:last-child) {
				th,
				td {
					border-bottom: 1px solid $table-line--borderColor; // * TODO: possibly rework variable naming
				}
			}
		}
	}

	&[data-border="around"] {
		tbody {
			th,
			td {
				&:not(:first-child) {
					border-left: 1px solid $table-line--borderColor; // * TODO: possibly rework variable naming
				}
			}
		}
	}

	// Zebra cell styling
	&[data-rows="zebra"] {
		tbody {
			@include cmp-table-zebra(
				$table-row-zebra-color
			); // * TODO: possibly rework variable naming
		}
	}

	// Densities
	&[data-density="small"] {
		th,
		td {
			padding: $table-densitySmall--padding;
		}
	}

	&[data-density="large"] {
		th,
		td {
			padding: $table-densityLarge--padding;
		}
	}

	// Sticky header
	&[data-sticky="header"] {
		thead,
		tbody th {
			background-color: #fff;
			position: sticky;
			top: -1px;
		}
	}
}
