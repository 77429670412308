@import "../../../css/partials.meta";
@import "pagination.variables";

.cmp-pagination {
	color: $db-color-cool-gray-700;
	font-size: to-rem($pxValue: 14);

	user-select: none;

	& > ol {
		display: flex;
	}

	.elm-headline {
		@include a11y-visually-hidden($partial: $partial);
	}

	li,
	.elm-link {
		align-items: center;
		display: inline-flex;
		justify-content: center;
	}

	li {
		height: to-rem($pxValue: 36);
		width: to-rem($pxValue: 36);
	}

	.elm-link {
		color: inherit;
		height: 100%;
		text-decoration: none;
		width: 100%;

		&[rel="prev"],
		&[rel="next"] {
			padding: 0;
			vertical-align: bottom;
		}

		&[rel="prev"]::before {
			margin-left: to-rem($pxValue: 11);
		}

		&[rel="next"]::before {
			margin-right: to-rem($pxValue: 11);
		}

		&[rel="prev"] {
			@include icon(glyph(chevron-left), $partial: $partial);
			@include is-icon-text-replace();
		}

		&[rel="next"] {
			@include icon(glyph(chevron-right), $partial: $partial);
			@include is-icon-text-replace();
		}

		&[aria-current="page"] {
			font-weight: 700;
		}
	}
}
