@import "../../../css/db-ui-core.variables";
@import "fonts.variables";

@each $font-name, $font-meta in $font-families {
	$font-family: map-get($font-meta, "font-family");
	$font-filename: map-get($font-meta, "font-filename");
	$font-weight: map-get($font-meta, "font-weight");
	$font-style: map-get($font-meta, "font-style");
	$font-local-name: map-get($font-meta, "font-local-name");
	$font-local-name-short: map-get($font-meta, "font-local-name-short");

	@font-face {
		font-family: $font-family;
		font-style: $font-style;
		font-weight: $font-weight;
		@if $font-local-name and $font-local-name-short {
			src:
				local("#{$font-name}"),
				local("#{$font-local-name}"),
				local("#{$font-local-name-short}"),
				url("#{$fonts-path}#{$font-filename}.woff2?4r0080")
					format("woff2"),
				url("#{$fonts-path}#{$font-filename}.woff?4r0080")
					format("woff");
		} @else if $font-local-name {
			src:
				local("#{$font-name}"),
				local("#{$font-local-name}"),
				url("#{$fonts-path}#{$font-filename}.woff2?4r0080")
					format("woff2"),
				url("#{$fonts-path}#{$font-filename}.woff?4r0080")
					format("woff");
		} @else if $font-local-name-short {
			src:
				local("#{$font-name}"),
				local("#{$font-local-name-short}"),
				url("#{$fonts-path}#{$font-filename}.woff2?4r0080")
					format("woff2"),
				url("#{$fonts-path}#{$font-filename}.woff?4r0080")
					format("woff");
		} @else {
			src:
				local("#{$font-name}"),
				url("#{$fonts-path}#{$font-filename}.woff2?4r0080")
					format("woff2"),
				url("#{$fonts-path}#{$font-filename}.woff?4r0080")
					format("woff");
		}
	}
}
