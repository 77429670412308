@import "../../../css/partials.meta";
// TODO: Replace magic numbers e.g. with flexbox implementations and extract variables as well for multi-brand
@import "mainnavigation.variables";

.cmp-mainnavigation {
	position: $mainnavigation---position;
	top: $mainnavigation---position-top;

	.rea-header & {
		order: 1;
	}

	// Header Components positioning, :has non-supporting browsers fallback
	@supports not selector(:has(db)) {
		margin: auto;
	}

	// The links shouldn't get an underline as text-decoration
	.elm-link {
		// We need to reset the general border-radius here
		border-radius: initial;
		color: inherit;
		display: inline-block;
		text-decoration: none;
	}

	// Navigation toggle elements
	& > input[type="checkbox"][id] {
		@include a11y-visually-hidden($partial: $partial);

		@media screen and (min-width: 1024px) {
			// Hiding the navigation toggle elements on huger viewports
			&,
			& + label[for] {
				display: none;
			}
		}
	}

	& > label[for] {
		@include icon(glyph(menu), 24, "outline", $partial: $partial);
		@include icon(glyph(close), 24, "outline", "after", $partial: $partial);

		background-color: $header---backgroundColor; // TODO: This would need to get replaced by the correct (semantic) color

		border-bottom: 1px solid $db-color-warm-gray-100;

		display: block;
		padding: to-rem($pxValue: 8) to-rem($pxValue: 22) to-rem($pxValue: 10);

		&::before {
			margin-right: to-rem($pxValue: 16);
		}

		&::after {
			position: absolute;
			right: to-rem($pxValue: 16);
			transform: translateY(to-rem($pxValue: 2));
		}
	}

	// Displaying the main navigation as an top to bottom overlay on smaller viewports
	@media screen and (max-width: 1023px) {
		background-color: $db-color-cool-gray-600;
		left: 0;
		position: absolute;
		top: to-rem($pxValue: 64);

		width: 100vw;
		z-index: $z-index-cmp-mainnavigation-dropdown; // * TODO: possibly rework variable naming

		& > input[type="checkbox"][id]:not(:checked) {
			// Hiding the cross on collapsed navigation
			& + label[for] {
				&::after {
					content: none;
				}
			}
			// Hiding the navigation
			& ~ ul {
				display: none;
			}
		}
	}

	ul {
		background-color: $header---backgroundColor;
	}

	// Multiple level navigation
	ul ul {
		@media screen and (min-width: 1024px) {
			box-shadow: $box-shadow-01, $box-shadow-02;
			position: absolute;
			visibility: hidden;

			z-index: $z-index-rea-header-cmp-mainnavigation-submenu;
		}

		@media screen and (max-width: 1023px) {
			display: none;
		}
	}

	// First Level
	& > ul {
		@media screen and (min-width: 1024px) {
			display: flex;
		}

		& > li,
		& > * > li {
			& > .elm-link,
			& > * > .elm-link {
				@media screen and (min-width: 1024px) {
					padding: $mainnavigation-link--paddingTop
						to-rem($pxValue: 26) $mainnavigation-link--paddingBottom;

					border-#{$mainnavigation-link--borderPosition}: 4px solid
						transparent;

					&:hover,
					&:focus {
						border-#{$mainnavigation-link--borderPosition}-color: $db-color-cool-gray-200;
					}

					&[aria-current="page"] {
						border-#{$mainnavigation-link--borderPosition}-color: $db-color-red-500;
					}
				}
			}

			// From second level
			ul {
				@media screen and (min-width: 1024px) {
					left: 0;
					min-width: 100%;
					top: to-rem($pxValue: 79);

					// From third level
					ul {
						left: 100%;
						top: auto;
						transform: translateY(-#{to-rem($pxValue: 53)});
					}

					.elm-link {
						align-items: center;
						display: flex;

						min-height: to-rem($pxValue: 53);
						padding-left: to-rem($pxValue: 18);
						padding-right: to-rem($pxValue: 18);
					}
				}

				& > li,
				& > * > li {
					& > .elm-link,
					& > * > .elm-link {
						@media screen and (max-width: 1023px) {
							padding-left: to-rem($pxValue: 34);
						}
					}

					// Third level
					ul {
						& > li,
						& > * > li {
							& > .elm-link,
							& > * > .elm-link {
								@media screen and (max-width: 1023px) {
									padding-left: to-rem($pxValue: 60);
								}
							}
						}
					}
				}
			}
		}
	}

	li {
		display: block;

		// lower levels
		position: relative;
		// Icon for menu
		// TODO: this could probably get simplified with a media query polyfill and moved back to the previous section
		&[aria-haspopup="true"] {
			@include icon(
				glyph(chevron-right),
				24,
				"outline",
				"after",
				$partial: $partial
			);

			&::after {
				position: absolute;
				right: 1rem;
				top: to-rem($pxValue: 9);
			}
		}

		&:hover {
			& > ul,
			& > * > ul {
				visibility: visible;
				@media screen and (max-width: 1023px) {
					display: initial;
				}
			}
		}

		@media screen and (max-width: 1023px) {
			background-color: $db-color-cool-gray-600;
			color: #fdfdfd; // TODO: This would need to get replaced by the correct (semantic) color

			&:hover {
				background-color: $db-color-cool-gray-300;
				color: $db-color-cool-gray-800;

				& > a {
					font-weight: 700;
				}
			}

			.elm-link {
				color: inherit;
				padding-bottom: to-rem($pxValue: 8);

				padding-left: to-rem($pxValue: 22);
				padding-top: to-rem($pxValue: 8);
			}

			&[aria-haspopup="true"] {
				position: relative;
			}
		}
		@media screen and (min-width: 1024px) {
			// Do not show Icon for menu on bigger screens
			&[aria-haspopup="true"] {
				&::after {
					content: none;
				}
			}

			.elm-link {
				&:hover,
				&:focus {
					background-color: $db-color-cool-gray-200;
					color: initial;
				}

				&[aria-current="page"] {
					font-weight: 700;
				}
			}
		}
	}
}
