@import "tag.variables";

.elm-tag {
	align-items: center;
	background-color: $tag---backgroundColor;
	border-radius: $tag---radius;
	color: $tag---color;
	display: inline-flex;
	font-weight: 500;

	// Link related styles
	font-size: to-rem($pxValue: 12);
	height: to-rem($pxValue: 24);
	justify-content: center;
	padding-left: to-rem($pxValue: 4);
	padding-right: to-rem($pxValue: 4);
	text-transform: capitalize;
	white-space: nowrap;

	// Sizes
	&%size-Small {
		height: to-rem($pxValue: 20);
	}

	&[data-size="small"] {
		@extend %size-Small;
	}

	//*** Variants ***
	&[data-variant="light"] {
		background-color: $db-color-cool-gray-200;
		color: $db-color-cool-gray-700;
		border: 1px solid $db-color-cool-gray-400;
	}

	&[data-variant="warning"] {
		background-color: $db-color-warning-small-font;
	}

	&[data-variant="error"] {
		background-color: $db-color-error-small-font;
	}

	&[data-variant="informative"] {
		background-color: $db-color-informative-small-font;
	}

	&[data-variant="success"] {
		background-color: $db-color-success-small-font;
	}

	// The "Gleis" additioally gets a bold font weight
	&[data-variant="track"] {
		background-color: $db-color-blue-700;
		font-weight: 700;
	}

	// POIs
	&[data-variant="poi-essen-trinken"] {
		background-color: $db-color-food-drink;
		color: $db-color-cool-gray-700;
	}

	&[data-variant="poi-einkaufen"] {
		background-color: $db-color-shopping;
	}

	&[data-variant="poi-gesundheit"] {
		background-color: $db-color-health;
	}

	&[data-variant="poi-kunst-kultur"] {
		background-color: $db-color-arts-culture;
	}

	&[data-variant="poi-wissenswertes"] {
		background-color: $db-color-things-to-know;
	}

	&[data-variant="poi-freizeit"] {
		background-color: $db-color-leisure;
	}

	&[data-variant="poi-zivile-rel-einrichtungen"] {
		background-color: $db-color-community-facilities;
	}

	&[data-variant="poi-dienstleistungen"] {
		background-color: $db-color-services;
		color: $db-color-cool-gray-700;
	}

	&[data-variant="poi-db-services-einrichtung"] {
		background-color: $db-color-db-services-facilities;
	}

	&[data-variant="poi-wegeleitung"] {
		background-color: $db-color-guidance;
	}

	// Icons
	&[data-icon],
	&[data-icon-before] {
		@include iconMeta(20);

		&::before {
			--icon-margin-after: #{to-rem($pxValue: 2)};
		}
	}

	&[data-icon-after] {
		@include iconMeta($size: 20, $position: "after");

		&::after {
			--icon-margin-before: #{to-rem($pxValue: 2)};
		}
	}
}
