@import "../../../css/partials.meta";
@import "dialog.variables";

.cmp-dialog {
	border: 0;
	border-radius: 8px;

	color: $db-color-cool-gray-700;

	font-size: to-rem($pxValue: 14);
	margin-top: 0;
	max-width: 90vw;

	padding: to-rem($pxValue: 16); // TODO: replace by general spacing variables

	// Display the dialog fixed within the middle of the screen (actually some overwrites for the dialog-polyfill CSS)
	position: fixed;
	top: 50%;
	transform: translate(0, -50%);
	@media screen and (min-width: 768px) {
		// TODO: replace by correct general breakpoint variables
		padding: to-rem(
			$pxValue: 24
		); // TODO: replace by general spacing variables
	}
	// Defining the backdrop colors

	// * native
	&::backdrop {
		background-color: $dialog-backdrop--backgroundColor;
	}

	// * polyfill
	& + .backdrop {
		background-color: $dialog-backdrop--backgroundColor;
	}

	// Display the modal via CSS as a no-javascript fallback
	&:target {
		.no-js & {
			display: initial;
		}
	}

	header {
		color: $db-color-cool-gray-800;

		transform: translateY(-#{to-rem($pxValue: 9)});

		.elm-headline {
			font-size: to-rem($pxValue: 18);
			font-weight: 700;
			line-height: to-rem(
				$pxValue: 24
			); // TODO: Check whether this is a settting that should be more global
			margin-bottom: to-rem($pxValue: 6);
			margin-right: to-rem($pxValue: 24);
			// Remove the headlines margin-top, as we're in a dialog that has a padding on it's own
			margin-top: 0;

			text-align: center;
			@media screen and (min-width: 768px) {
				// TODO: replace by correct general breakpoint variables
				font-size: to-rem($pxValue: 24);
				line-height: to-rem(
					$pxValue: 28
				); // TODO: Check whether this is a settting that should be more global
			}
		}

		.elm-link {
			&.is-close {
				@include icon(glyph(close), 24, "outline", $partial: $partial);
				@include is-icon-text-replace();

				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	main {
		p {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	footer {
		display: flex;
		margin-top: to-rem($pxValue: 18);

		.elm-button {
			& + .elm-button {
				margin-left: to-rem(
					$pxValue: 16
				); // TODO: Replace by spacing variable
			}
		}

		&[data-alignment="end"] {
			justify-content: flex-end;
		}

		&:not([data-alignment="end"]) {
			.elm-button {
				flex-grow: 1;
			}
		}
	}
}
