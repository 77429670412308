@import "../../../css/helpers/functions";

.cmp-cards {
	list-style: none;

	margin: 0;
	padding: 0;

	@supports (display: grid) {
		display: grid;
		grid-gap: to-rem($pxValue: 24);
		grid-template-columns: repeat(
			auto-fill,
			minmax(to-rem($pxValue: 340), 1fr)
		);
	}
}
