@import "../../../css/helpers/functions";

$notification-alert--color: #fff !default;

$notification-success--backgroundColor: $db-color-success !default;
$notification-warning--backgroundColor: $db-color-warning !default;
$notification-error--backgroundColor: $db-color-error !default;
$notification-informative--backgroundColor: $db-color-informative !default;

$notification---backgroundColor: $db-color-cool-gray-700 !default;
