%icon {
	// Hiding icon from screenreaders
	-webkit-alt: "";
	alt: "";

	display: inline-block;
	/*** icon - placeholder ***/
	// * use !important to prevent issues with browser extensions that change fonts
	font-family: var(--icon-font-family) !important;

	// * Better Font Rendering ===========
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-variant: normal;
	font-weight: normal; // CSS variables fallback
	font-weight: var(--icon-font-weight, normal);
	line-height: 1;
	speak: none; // Hiding icon from screenreaders, fallback by older notation
	speak: never; // Hiding icon from screenreaders
	text-transform: none;

	vertical-align: middle;

	// Hiding icon from screenreaders
	@media aural {
		content: none;
	}
	@media speech {
		content: none;
	}
}

// TODO: The following construct was especially meant to enable a branch (EDS & FV) specific result - probably we could refactor this the sooner the later
%defaultIconCustomProperties {
	--icon-font-family: #{$icons-font-family} !important;
	--icon-font-size: #{to-rem($pxValue: $icon-size)};
}
