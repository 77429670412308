@charset "utf-8";

@import "toggle.variables";

// adapted by https://danklammer.com/articles/simple-css-toggle-switch/

.elm-toggle {
	appearance: none;

	background-color: $db-color-cool-gray-500;
	border: none;

	border-radius: to-rem($pxValue: 12);

	display: inline-block;
	height: to-rem($pxValue: 24);
	// margin-right: $db-spacing-xs;

	position: relative;

	transition: background-color 0.3s ease;

	width: to-rem($pxValue: 48);
	// Adopted by https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html
	@media (prefers-reduced-motion: reduce) {
		transition-duration: 0.01s !important;
	}

	// Label positioning
	& + .elm-label {
		vertical-align: top;
	}

	&::before {
		background-color: $db-color-white;

		border-radius: to-rem($pxValue: 10);

		box-shadow:
			0 5px 30px 0 rgba(0, 0, 0, 0.05),
			0 1px 5px 0 rgba(0, 0, 0, 0.15);
		content: ""; // we could even also add toggled textual content for the switch in the future

		display: block;
		height: to-rem($pxValue: 20);
		left: #{to-rem($pxValue: 2)};

		position: absolute;
		top: #{to-rem($pxValue: 2)};

		transition:
			border-color,
			left 0.3s cubic-bezier(0.3, 1.5, 0.7, 1);

		width: to-rem($pxValue: 20);
		// Adopted by https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html
		@media (prefers-reduced-motion: reduce) {
			transition-duration: 0.01s !important;
		}
	}

	&:hover {
		&::before {
			background-color: #f7f7f7; // TODO: Identify the correct color variable
		}
	}

	&:active {
		&::before {
			transition: width 0.3s cubic-bezier(0.3, 1.5, 0.7, 1);
			width: to-rem($pxValue: 24);
			// Adopted by https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html
			@media (prefers-reduced-motion: reduce) {
				transition-duration: 0.01s !important;
			}
		}
	}

	&:checked {
		background-color: $toggle--checked-backgroundColor;

		&::before {
			left: unset;
			right: to-rem($pxValue: 2);
		}
	}

	&:disabled {
		&,
		& + label {
			opacity: 0.5;
		}
	}
}
