@import "../../../css/helpers/functions";
@import "../../00-base/icons/icons.variables";
@import "../../00-base/icons/icons.helpers";

$tag---radius: to-rem(
	$pxValue: 4
) !default;

$tag---backgroundColor: $db-color-cool-gray-700 !default;
$tag---color: $db-color-white !default;
