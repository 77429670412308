// The main purpose of the styles included with this file are related to "regular" content pages.
// In case that you're building a web application you might want to have more freedom
// and probably would want to not include it's contents into your SCSS build / references.

@import "../../../css/helpers/clearfix";

@import "main.variables";

.rea-main {
	@include clearfix();

	background-color: $main---backgroundColor;
	// adapted from https://github.com/jgthms/bulma/blob/0.7.5/sass/elements/container.sass
	margin: 0 auto;

	padding: to-rem($pxValue: 16);
	position: relative;

	@media screen and (min-width: 1088px) {
		max-width: 960px;
		width: 960px;
	}
	@media screen and (min-width: 1280px) {
		max-width: 1152px;
		width: 1152px;
	}
	@media screen and (min-width: 1472px) {
		max-width: 1344px;
		width: 1344px;
	}
}
