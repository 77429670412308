@import "../../../css/helpers/functions";

// offset-x | offset-y | blur-radius | spread-radius | color
$header---boxShadow:
	0 2px 4px 0 rgba(0, 0, 0, 0.1),
	0 4px 8px 1px rgba(0, 0, 0, 0.05) !default;

$header---backgroundColor: #fdfdfd !default; // TODO: This would need to get replaced by the correct (semantic) color

$header---borderBottom: #{to-rem(
		$pxValue: 1
	)} solid $db-color-warm-gray-400 !default;

$header---marginBottom: to-rem(
	$pxValue: 16
) !default;
