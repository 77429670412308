// @import "icons.variables";

// *** Attribute based method ***
@each $icon-name, $icon-glyph in $icon-glyphs {
	[data-icon="#{$icon-name}"],
	[data-icon-before="#{$icon-name}"] {
		--icon-glyph-before: "#{$icon-glyph}";
	}
	[data-icon-after="#{$icon-name}"] {
		--icon-glyph-after: "#{$icon-glyph}";
	}
}
