@charset "utf-8";

@import "../../../css/partials.meta";
@import "../../../css/helpers/functions";
@import "footer.variables";

.rea-footer {
	@include clearfix($partial: $partial);

	background-color: $footer---backgroundColor;
	box-shadow: $footer---boxShadow;

	color: $db-color-cool-gray-500;
	margin-top: $footer---marginTop;
	padding: #{to-rem($pxValue: 21)} #{to-rem($pxValue: 16)};

	&.has-border {
		border-top: $footer---borderTop;
	}

	@media screen and (max-width: 767px) {
		text-align: center;
	}
}
