@charset "utf-8";

@import "../../../css/partials.meta";
@import "checkbox.variables";
@import "../form-elements";

.elm-checkbox {
	@include icon("\00A0", 24, "outline", $partial: $partial);
	align-content: center;

	appearance: none;
	background-color: $checkbox---backgroundColor;
	border: #{to-rem($pxValue: 1)} solid $checkbox---borderColor;
	border-radius: 4px;
	color: $checkbox---color;

	display: inline-flex;
	height: to-rem($pxValue: 24);
	justify-content: center;
	width: to-rem($pxValue: 24);

	&[type="checkbox"] {
		vertical-align: top;
	}

	&::before {
		--icon-font-size: 1.375rem;
		margin-right: 0;
	}

	&:checked {
		background-color: $checkbox--checked-backgroundColor;

		&::before {
			color: $checkbox--checked-color;
			content: glyph(done);
		}
	}

	&:enabled {
		&:hover {
			background-color: rgba(40, 45, 55, 0.14);

			&:checked {
				background-color: $checkbox-hover-checked-backgroundColor;
			}
		}

		&:active {
			background-color: rgba(40, 45, 55, 0.14);
		}
	}

	&:indeterminate {
		&::before {
			content: glyph(minus);
		}
	}

	&:disabled {
		&,
		& + label {
			opacity: 0.5;
		}
	}

	& + label {
		@extend %form-label;

		margin-left: $db-spacing-xs;
	}
}
