@import "../../../css/partials.meta";
@import "breadcrumb.variables";

.cmp-breadcrumb {
	color: $db-color-cool-gray-500;
	font-size: to-rem($pxValue: 12);

	a {
		color: inherit;
	}

	ol {
		display: flex;

		& > * {
			align-items: center;
			display: inline-flex;
			margin-right: to-rem($pxValue: 3);

			[aria-current="location"],
			[aria-current="page"] {
				color: $db-color-cool-gray-700;
				font-size: to-rem($pxValue: 14);
			}

			.elm-link {
				border-radius: to-rem($pxValue: 4);

				padding: to-rem($pxValue: 1) to-rem($pxValue: 3)
					to-rem($pxValue: 1) to-rem($pxValue: 4);
				text-decoration: none;

				&:hover {
					background-color: $breadcrumb-link-hover-backgroundColor;
				}

				&:active {
					background-color: $breadcrumb-link-active-backgroundColor;
				}
			}

			&:first-child {
				@include icon(glyph(home), $size: 20, $partial: $partial);

				&::before {
					--icon-margin-after: #{to-rem($pxValue: 3)};
				}
			}

			&:not(:first-child) {
				@include icon(
					glyph($breadcrumb-divider--icon),
					$size: 20,
					$partial: $partial
				);

				&::before {
					--icon-font-size: #{to-rem($pxValue: 14)};
					--icon-margin-after: 0;
					color: $db-color-cool-gray-400;
					margin-left: -#{to-rem($pxValue: 2)};
				}
			}
		}
	}
}
