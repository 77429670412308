$form-elements-color: $db-color-cool-gray-700 !default;
$form-elements-border-radius: 4px !default;

$form-elements---borderTop: 1px solid var(--formElement---borderTopColor) !default;
$form-elements---borderRight: 1px solid var(--formElement---borderRightColor) !default;
$form-elements---borderBottom: 1px solid var(--formElement---borderBottomColor) !default;
$form-elements---borderLeft: 1px solid var(--formElement---borderLeftColor) !default;

// ### Style variations ###
// Semitransparent
$form-elements-semitransparent-background-color: rgba(
	$db-color-cool-gray-700,
	0.05
) !default;
$form-elements-semitransparent-border-bottom-color: rgba(
	$db-color-cool-gray-700,
	0.5
) !default;

// White
$form-elements-white-background-color: $db-color-white !default;
$form-elements-white-border-bottom-color: rgba(
	$db-color-cool-gray-700,
	0.5
) !default;

// Solid
$form-elements-solid-background-color: $db-color-cool-gray-100 !default;
$form-elements-solid-border-bottom-color: rgba(
	$db-color-cool-gray-700,
	0.5
) !default;

// Outline
$form-elements-outline-border-color: rgba(
	$db-color-cool-gray-700,
	0.25
) !default;
