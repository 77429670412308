@import "../../../css/partials.meta";
@import "accordion.variables";

.cmp-accordion {
	--db-accordion---paddingLeft: #{to-rem($pxValue: $accordion---paddingLeft)};
	border-bottom: 1px solid $db-color-cool-gray-200;
	padding-left: var(--db-accordion---paddingLeft);
	padding-right: $accordion---paddingRight;

	summary {
		@include icon(
			glyph(expand-more),
			24,
			"outline",
			"after",
			$partial: $partial
		);
		align-items: center;
		display: flex;
		justify-content: space-between;

		padding-block: $db-spacing-sm;
		// Negating the padding left for moving the element into that direction with the same measures
		transform: translateX(calc(var(--db-accordion---paddingLeft) * -1));
		width: calc(
			100% + var(--db-accordion---paddingLeft) + #{$accordion---paddingRight}
		);

		// Replace existing marker with an icon from the library
		&::-webkit-details-marker {
			display: none;
		}
		// … and using some other declaration for Mozilla Firefox
		&:first-of-type {
			list-style-type: none;
		}

		// Icon horizontal space
		&::after {
			--icon-margin-before: #{to-rem($pxValue: 8)};
			margin-right: #{to-rem($pxValue: 14)};
		}

		& + p {
			margin-top: 0;
		}
	}
	// TODO: This might get simplified in the future with @nest
	&[open] > summary {
		&::after {
			content: glyph(expand-less);
		}
	}

	// Emphasis
	&%emphasis-High {
		// Calculate especially depending on the icons sizes difference
		--db-accordion---paddingLeft: #{to-rem(
				$pxValue: $accordion---paddingLeft + 8
			)};

		summary {
			font-weight: 700;
			height: to-rem($pxValue: 56);

			&::after {
				--icon-font-size: #{to-rem($pxValue: 32)};
			}
		}
	}

	&[data-emphasis="high"] {
		@extend %emphasis-High;
	}

	// Sizes
	&%size-Small {
		// Calculate dependending on the icons sizes and margins difference
		--db-accordion---paddingLeft: #{to-rem(
				$pxValue: $accordion---paddingLeft - 12
			)};

		summary {
			font-size: to-rem($pxValue: 14);
			// ToDo: This needs to get replaced by the correct variable
			padding-block: 0.59375rem;

			&::after {
				--icon-font-size: #{to-rem($pxValue: 20)};
				--icon-margin-after: #{to-rem($pxValue: 6)};
				margin-left: #{to-rem($pxValue: 6)};
			}
		}
	}

	&%size-Large {
		// Calculate especially depending on the icons sizes difference
		--db-accordion---paddingLeft: #{to-rem(
				$pxValue: $accordion---paddingLeft + 8
			)};

		summary {
			font-size: to-rem($pxValue: 20);
			padding-block: $db-spacing-m;

			&::after {
				--icon-font-size: #{to-rem($pxValue: 32)};
			}
		}
	}

	&[data-size="small"] {
		@extend %size-Small;
	}

	&[data-size="large"] {
		@extend %size-Large;
	}
}
