// Box sizing
html {
	box-sizing: var(--db-box-sizing, border-box);
}

*,
::before,
::after {
	box-sizing: inherit;
}

// Media
img,
embed,
iframe,
object,
video {
	height: auto;
	max-width: 100%;
}

// Iframe
iframe {
	border: 0;
}

// * Individual styles
// Navigation lists
nav,
[role="navigation"] {
	ol,
	ul {
		list-style: none;

		margin: 0;
		padding: 0;
	}
}

// General styles (TODO: most likely move those to DB UI Base)
:root {
	--db-focus-outline-offset: 1px;
	--db-focus-outline-color: #2c95c4;
}

// Focus styles
:is(a, button, input, textarea, summary) {
	--db-focus-outline-size: max(2px, 0.08em);

	&:focus-visible {
		outline: var(--db-focus-outline-size)
			var(--db-focus-outline-style, solid)
			var(--db-focus-outline-color, currentColor);
		outline-offset: var(
			--db-focus-outline-offset,
			var(--db-focus-outline-size)
		);
	}
}

// List style / https://marketingportal.extranet.deutschebahn.com/de/brandguide/typografie-1#8e0be060-c2f5-42a1-abbc-6b050845d73c~paragraph--image_link
:where(ul) {
	list-style-type: "\2022"+ "   ";

	li::marker {
		color: $db-color-red-500;
	}
}

// details/summary box-sizing / https://kittygiraudel.com/2021/08/23/shadow-roots-and-inheritance/
details {
	& > *,
	& > ::before,
	& > ::after {
		box-sizing: var(--db-box-sizing, border-box);
	}
}
