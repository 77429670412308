@import "../../../css/partials.meta";
@import "tab-bar.variables";

.cmp-tab-bar {
	display: flex;
	flex-wrap: wrap;

	label[role="tab"],
	section[role="tabpanel"] {
		background-color: $tabBar-tabSection--backgroundColor;
	}

	& > input[type="radio"],
	& > * > input[type="radio"] {
		@include a11y-visually-hidden($partial: $partial);

		& + label[role="tab"] {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			cursor: pointer;
			display: inline-block;
			margin-left: to-rem($pxValue: 8);
			padding: #{to-rem($pxValue: 5)} #{to-rem($pxValue: 32)};
			position: relative;
			z-index: 1;

			&:first-of-type {
				margin-left: to-rem($pxValue: 16);
			}
		}

		&:focus + label {
			outline: $tabBar-tab-focus-outline;
		}

		&:not(:checked) {
			& + label[role="tab"] {
				&:hover,
				&:focus {
					background-color: $tabBar-tab-hover-backgroundColor;
				}
			}
		}

		&:disabled {
			& + label[role="tab"] {
				color: $db-color-cool-gray-400;

				&:hover,
				&:focus {
					background-color: unset;
				}
			}
		}

		&:checked {
			& + label[role="tab"] {
				border: $tabBar-tab-active-border;
				border-bottom: none;
				font-weight: 700;
				padding-top: to-rem($pxValue: 3);
				transform: translateY(to-rem($pxValue: 1));

				& + section[role="tabpanel"] {
					display: initial;
				}
			}
		}
	}

	section[role="tabpanel"] {
		border-top: $tabBar-tabpanel--borderTop;
		box-shadow: $tabBar-tabpanel--boxShadow;
		display: none;
		flex-basis: 100%;
		order: 1;
		padding: to-rem($pxValue: 46) to-rem($pxValue: 50);
	}
}
