@import "../../../css/partials.meta";
@import "header.variables";
@import "../../../css/helpers/functions";

.rea-header {
	background-color: $header---backgroundColor;
	box-shadow: $header---boxShadow;
	display: flex;
	justify-content: space-between;
	margin-bottom: $header---marginBottom;

	// TODO: all of the header declarations need to get realigned and most likely need to get set up via flexbox for simplification
	min-height: to-rem($pxValue: 79);
	padding-left: to-rem($pxValue: 16);
	padding-right: to-rem($pxValue: 16);

	// We'll need to keep this for legacy reasons, to position the navigation even within a metanavigation included in header
	&:not(:has(.rea-meta)) {
		&::after {
			content: "";
			display: inline-block;
			order: 3;
		}
	}

	.elm-link {
		display: inline-block;
	}
	@media screen and (max-width: 1023px) {
		border-bottom: $header---borderBottom;
		min-height: to-rem($pxValue: 64);
	}
}
