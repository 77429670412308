@import "../../../css/partials.meta";
@import "select.variables";
@import "../form-elements";
@import "../../../css/db-ui-core.variables";

.elm-select {
	@extend %form-element;
	appearance: none;
	font-size: to-rem($pxValue: 16);

	// For IE
	&::-ms-expand {
		display: none;
	}

	// Style the regular (non-multiple) select elements
	&:not([multiple]) {
		// Semitransparent is the default style
		@at-root :where(#{&}) {
			@extend %form-element-semitransparent;
		}

		background-image: var(
			--db-ic-expand-more-20,
			url(#{$icons-path}functional/images/navigation/db_ic_expand_more_20.svg)
		);
		background-position: right to-rem($pxValue: 11) center;
		background-repeat: no-repeat;
		height: to-rem($pxValue: 44);
		padding: to-rem($pxValue: 20) to-rem($pxValue: 42) 0 $db-spacing-m;
		// No need to reposition the included value on hidden label
		&:has(+ label[data-label-hidden="true"]) {
			padding-top: 0;
		}

		// Floating labels
		& + .elm-label {
			pointer-events: none;
			position: absolute;
			transition:
				opacity,
				transform 250ms;
			// Adopted by https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html
			@media (prefers-reduced-motion: reduce) {
				transition-duration: 0.01s !important;
			}
		}

		&:has(option:checked:empty) {
			& + .elm-label {
				color: $select-floatingLabel--color;
				font-size: to-rem($pxValue: 16);
				transform: translate($db-spacing-m, -#{to-rem($pxValue: 35)});
			}
		}

		// Validation
		/* stylelint-disable order/order */
		@extend %form-element-validation-programmatic;
		/* stylelint-enable order/order */
		&,
		&[data-variant]  {
			&:not([aria-invalid]) {
				&:is(:user-valid) {
					--formElement---borderColor: #{$db-color-green-600};
				}

				&:is(:user-invalid) {
					--formElement---borderColor: #{$db-color-red-500};
				}
			}
		}
	}

	// TODO: Evaluate whether those could get moved to ../_form-elements.scss
	&:disabled,
	fieldset:disabled & {
		&,
		& + .elm-label {
			// Decided against cursor: not-allowed, compare to e.g. https://phabricator.wikimedia.org/T121960
			opacity: 0.4;
		}
	}

	// ### Style variations
	&[data-variant="semitransparent"] {
		@extend %form-element-semitransparent;
	}

	&[data-variant="white"] {
		@extend %form-element-white;
	}

	&[data-variant="solid"] {
		@extend %form-element-solid;
	}

	&[data-variant="outline"] {
		@extend %form-element-outline;
	}

	// Floating labels
	& + .elm-label {
		@extend %form-label;

		display: block;
		font-size: to-rem($pxValue: 12);
		margin-bottom: -#{to-rem($pxValue: 18)};
		transform: translate($db-spacing-m, -#{to-rem($pxValue: 41)});
		transition:
			opacity,
			transform 150ms;
		// Adopted by https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html
		@media (prefers-reduced-motion: reduce) {
			transition-duration: 0.01s !important;
		}
	}

	&[multiple] {
		margin-top: to-rem($pxValue: 16);

		& + .elm-label {
			transform: translate($db-spacing-m, -#{to-rem($pxValue: 100)});
		}
	}

	optgroup {
		background-color: transparent;
		font-style: normal;
	}

	option {
		&:not(:disabled):hover {
			background-color: $db-color-cool-gray-200;
		}

		&:checked {
			background-color: $db-color-cool-gray-200;
		}
	}
}
