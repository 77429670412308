@charset "utf-8";

@import "../../../css/partials.meta";
@import "textarea.variables";
@import "../form-elements";

.elm-textarea {
	@extend %form-element;

	// Semitransparent is the default style
	@at-root :where(#{&}) {
		@extend %form-element-semitransparent;
	}

	display: block;

	font-size: to-rem($pxValue: 16);
	height: to-rem($pxValue: 44);
	line-height: to-rem($pxValue: 24);
	max-width: 100%;

	padding: to-rem($pxValue: 6) to-rem($pxValue: 10);

	resize: vertical;

	width: 100%;

	// * to be evaluated
	// display: inline-flex;
	// align-items: center;
	// justify-content: flex-start;

	.elm-label:has(+ &) {
		@extend %form-label;
	}

	&:not([rows]) {
		max-height: to-rem($pxValue: 600);
		min-height: to-rem($pxValue: 120);
	}

	&[rows] {
		height: initial;
	}

	// TODO: Evaluate whether those could get moved to ../_form-elements.scss
	&:focus,
	&:hover {
		&:not(:disabled) {
			--formElement---borderColor: #{$db-color-cool-gray-400};
		}
	}

	// TODO: Evaluate whether those could get moved to ../_form-elements.scss
	&:disabled,
	fieldset:disabled & {
		&,
		& + .elm-label {
			// Decided against cursor: not-allowed, compare to e.g. https://phabricator.wikimedia.org/T121960
			opacity: 0.4;
		}
	}

	// TODO: Evaluate whether those could get moved to ../_form-elements.scss
	& + output {
		// amount of signs output
		float: right;

		margin-top: to-rem($pxValue: 12);

		// Description styles
		// TODO: This probably still needs to get further changed to a general "hint" pattern or similar
		&,
		& + .description {
			color: $db-color-cool-gray-500;
			font-size: to-rem($pxValue: 14);
			padding: to-rem($pxValue: 6) to-rem($pxValue: 10);
		}
	}

	// ### Style variations
	&[data-variant="semitransparent"] {
		@extend %form-element-semitransparent;
	}

	&[data-variant="white"] {
		@extend %form-element-white;
	}

	&[data-variant="solid"] {
		@extend %form-element-solid;
	}

	&[data-variant="outline"] {
		@extend %form-element-outline;
	}

	// Validation
	/* stylelint-disable order/order */
	@extend %form-element-validation;
	@extend %form-element-validation-programmatic;
	/* stylelint-enable order/order */
}
